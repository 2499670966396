<template>
  <editor-pane class="tree-pane">
    <h1>Tree</h1>
    <div class="tree">
      <ul>
        <tree-node
          v-for="language in roots"
          :key="language.identifier"
          :language="language"
          class="root"
        />
      </ul>
    </div>
  </editor-pane>
</template>

<script>
import EditorPane from '@/components/EditorPane.vue'
import TreeNode from '@/components/TreeNode.vue'

export default {
  name: 'TreePane',
  components: { EditorPane, TreeNode },
  props: { project: Object },
  computed: {
    roots () {
      const project = this.$store.state.editor.project
      const roots = project?.roots ?? new Map()
      return Array.from(roots.values())
    },
  },
}
</script>

<style lang="scss">
$border-width: 2px;

.tree-pane {
  padding: 1rem;
}

.tree {
  --border: #{$border-width} solid var(--c-border);

  ul {
    position: relative;
    padding: 1em 0;
    white-space: nowrap;
    margin: 0 auto;
    text-align: center;

    &::after {
      content: '';
      display: table;
      clear: both;
    }

    ul {
      &::before {
        content: '';
        position: absolute;
        top: 0;
        left: 50%;
        border-left: var(--border);
        width: 0;
        height: 1em;
      }
    }
  }

  li {
    display: inline-block;
    vertical-align: top;
    text-align: center;
    list-style-type: none;
    position: relative;
    padding: 1em .5em 0 .5em;

    &::before,
    &::after {
      content: '';
      position: absolute;
      top: 0;
      right: 50%;
      border-top: var(--border);
      width: 50%;
      height: 1em;
    }

    &::after {
      right: auto;
      left: 50%;
      border-left: var(--border);
    }

    &:only-child {
      padding-top: 0;
      top: -#{$border-width};

      &::after,
      &::before {
        display: none;
      }
    }

    &:first-child::before,
    &:last-child::after {
      border: 0 none;
    }

    &:last-child::before{
      border-right: var(--border);
      border-radius: 0 5px 0 0;
    }

    &:first-child::after{
      border-radius: 5px 0 0 0;
    }

    &.root::before,
    &.root::after {
      display: none;
    }

    button {
      border: var(--border);
      padding: 0.5em 1em;
      text-decoration: none;
      display: inline-block;
      border-radius: 5px;
      color: var(--c-txt-primary);
      position: relative;
      top: $border-width;

      &:hover {
        border-color: var(--c-bg-alt);
      }
    }

    &.selected > button {
      background: var(--c-bg-alt);
      border-color: var(--c-bg-alt);
      color: var(--c-txt-alt);
    }
  }
}
</style>
