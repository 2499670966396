<template>
  <li class="tree-node" :class="{ selected }">
    <button @click="onClick">{{ language.name }}</button>
    <ul class="children" v-if="children.size > 0">
      <tree-node v-for="child in children" :key="child.identifier" :language="child"/>
    </ul>
  </li>
</template>

<script>
export default {
  name: 'TreeNode',
  props: { language: Object },
  computed: {
    children () {
      return this.language.children
    },
    tag () {
      return this.$store.state.editor.tag
    },
    selected () {
      return this.language === this.tag?.language
    },
  },
  methods: {
    onClick () {
      const year = Array.from(this.language.milestones.values())[0]
      this.$store.commit('setTag', {
        year,
        language: this.language,
      })
    },
  },
}
</script>
