<template>
  <section class="editor-pane" :class="{ visible }">
    <slot />
  </section>
</template>

<script>
export default {
  name: 'EditorPane',
  props: { visible: Boolean },
}
</script>

<style lang="scss" scoped>
.editor-pane {
  width: 100%;
}
</style>
